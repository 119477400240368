import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path:'*',
    redirect: {
      name: 'home',
    }
  },
  {
		name: 'home',
		path:'/',
		component: () => import('./view/match/home'),
		meta: {
			title: '商贸配对'
		}
	},
  {
		name: 'accentFurnitures',
		path:'/accentFurnitures',
		component: () => import('./view/match//accentFurnitures'),
		meta: {
			title: '商贸配对'
		}
	},
  {
		name: 'otherResult',
		path:'/otherResult',
		component: () => import('./view/match//otherResult'),
		meta: {
			title: '商贸配对'
		}
	},
  {
		name: 'workplaceIndex',
		path:'/workplaceIndex',
		component: () => import('./view/workplace/index'),
		meta: {
			title: '国际公建设计艺术展'
		}
	},
  {
		name: 'workplaceSelect',
		path:'/workplaceSelect',
		component: () => import('./view/workplace/select'),
		meta: {
			title: '国际公建设计艺术展'
		}
	},
  {
		name: 'invite',
		path:'/invite',
		component: () => import('./view/invite/invite'),
		meta: {
			title: 'REAL+精彩之夜奢华夜宴'
		}
	},
];

// // 给路由添加访问路径
// routes.map(route => {
// 	route.name && (route.path = '/' + route.name)
// });

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  next();
});

export {
  router
};
