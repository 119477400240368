import Vue from 'vue';
import App from './App';
import { router } from './router';

import './assets/css/style.css';
import {
  Button,
	Checkbox,
	CheckboxGroup,
	Field,
	RadioGroup,
	Radio,
	Area,
	Popup,
	Toast
} from 'vant';
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Toast);
new Vue({
	router,
	el: '#app',
	render: h => h(App)
});